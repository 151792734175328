import {get as lodashGet} from 'lodash';
import { SUPPORT_URL } from '../../constants';
import * as analytics from '../../analytics';

export const getSidebarItems = (permissions) => {
    const defaultSidebarItems = [
        'dashboard',
        'invite-feedback',
        'manage-feedback',
    ];
    const sidebarItems = lodashGet(permissions, ['sidebar_items', 'main'], defaultSidebarItems);

    const lookup = {
        dashboard: {
            sort: 0,
            href: '/',
            label: 'Home',
            gka: 'dashboard',
        },
        'invite-feedback': {
            sort: 1,
            label: 'Invite Feedback',
            href: '/invite-reviews',
            gka: 'invite-feedback',
        },
        'manage-feedback': {
            sort: 2,
            label: 'Manage Feedback',
            href: '/reviews',
            gka: 'manage-feedback',
        },
    };

    return sidebarItems
        .map(sidebarItemGka => lookup[sidebarItemGka])
        .sort((a, b) => a.sort - b.sort);
};
export const getVerifiedSideBars = (professionalId, permissions) => {
    const defaultVerifiedSidebarItems = [
        'reputation',
        'enquiries',
    ];

    const sidebarItems = lodashGet(permissions, ['sidebar_items', 'verified'], defaultVerifiedSidebarItems);
    const canAccessVerifiedSidebars = lodashGet(permissions, 'can_access_verified_sidebars');
    const canUseReferralGenerator = lodashGet(permissions, 'can_use_referral_generator');

    const lookup = {
        reputation: {
            sort: 1,
            label: 'Reputation Tools',
            href: '/reputation',
            gka: 'reputation',
            disabled: !canAccessVerifiedSidebars,
        },
        fees: {
            sort: 2,
            label: 'Advice Fees',
            href: lodashGet(permissions, 'show_fees_insights_tab') ? '/fees-insights' : '/fees',
            gka: 'fees',
            disabled: !canAccessVerifiedSidebars,
        },
        referral: {
            sort: 3,
            label: 'Referral Generator',
            href: `/referral-generator/create`,
            gka: 'referral',
            disabled: !canUseReferralGenerator,
        },
        enquiries: {
            sort: 4,
            label: 'Enquiries',
            href: `/enquiries-list/${professionalId}`,
            gka: 'enquiries',
        },
    };

    return sidebarItems
        .map(sidebarItemGka => lookup[sidebarItemGka])
        .sort((a, b) => a.sort - b.sort);
};
export const getSettingsSideBars = (professionalId, permissions) => {
    const defaultVerifiedSidebarItems = [
        'profile',
        'firm',
        'billing',
        'help',
    ];

    const sidebarItems = lodashGet(permissions, ['sidebar_items', 'settings'], defaultVerifiedSidebarItems);

    const lookup = {
        profile: {
            sort: 0,
            label: 'Profile',
            href: `/professional/about/${professionalId}`,
            gka: 'profile',
        },
        firm: {
            sort: 1,
            label: 'Firm',
            href: `/professional/firm/${professionalId}`,
            gka: 'firm',
        },
        billing: {
            sort: 2,
            label: 'Billing',
            href: `/account-settings/${professionalId}`,
            gka: 'billing',
        },
        help: {
            sort: 4,
            label: 'Help Center',
            href: SUPPORT_URL.HELP_CENTRE,
            gka: 'help',
            external: true,
            onClick: () => {
                window.open(SUPPORT_URL.HELP_CENTRE, '_blank')
                analytics.track(`clicked 'help centre' in sidebar`, { category: 'Support' });
            },
        },
    };

    return sidebarItems
        .map(sidebarItemGka => lookup[sidebarItemGka])
        .sort((a, b) => a.sort - b.sort);
};
