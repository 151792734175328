import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../Components/Buttons/CustomButton';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '50%',
        transform: 'translate(-50%, -50%)',
        margin: 'auto',
        borderRadius: '5px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',

        [theme.breakpoints.down('sm')]: {
            width: '80%',
        },
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        textAlign: 'center',
    },
    textContainer: {
        marginBottom: '40px',
        marginTop: '30px',
    },
    text: {
        fontWeight: 'bold',
        lineHeight: 1,
        color: theme.palette.primary.light,
    },
}));

export function PrivateElevationDialog() {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <div className={classes.textContainer}>
                    <Typography className={classes.text}>
                        You need at least 5 reviews in order
                    </Typography>
                    <Typography className={classes.text}>
                        to view your Elevation dashboard
                    </Typography>
                </div>
                <Button
                    variant="contained"
                    color="tertiary"
                    href={'/invite-reviews'}
                >
                    Invite feedback now
                </Button>
            </CardContent>
        </Card>
    );
}