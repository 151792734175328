import React from 'react';
import PageTabs from '../../AppLayout/PageTabs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
    },
}));

export default function InviteFeedbackTabs() {
    const classes = useStyles();
    const routes = [
        {
            label: 'Invite Reviews',
            link: '/invite-reviews',
        },
        {
            label: 'See Who You\'ve Invited',
            link: '/review/previous-invites',
        },
    ];

    return (
        <div className={classes.root}>
            <PageTabs routes={routes}/>
        </div>
    );
};
