import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Sidebar as ReactAdminSidebar, TOGGLE_SIDEBAR, useLogout } from 'react-admin';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { hideSidebar, showSidebar } from '../../state/actions';
import List from '@material-ui/core/List';
import SidebarListItem from './SidebarListItem';
import { getSettingsSideBars, getSidebarItems, getVerifiedSideBars } from './getSidebarItems';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import getActiveSidebarItemGka from './getActiveSidebarItemGka';
import shouldShowSidebar from './shouldShowSidebar';
import SidebarHeading from './SidebarHeading';

const useStyles = makeStyles(theme => ({
    sidebar: {
        width: 210,
        '& > div': {
            width: '100%',
        },
    },
    sidebarMobile: {
        '& > div': {
            width: 270,
        },
    },
    mobileList: {
        paddingLeft: theme.spacing(1),
    },
}));

const Sidebar = props => {
    const classes = useStyles();
    const location = useLocation();
    const theme = useTheme();
    const logout = useLogout();
    const dispatch = useDispatch();
    const onMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const listRef = useRef(null);
    const { isLoggedIn, professionalId, firmId, permissions, basicProfileComplete, profileNeedsDetails } = props;
    const sidebarVisible = shouldShowSidebar(location.pathname, basicProfileComplete);
    const elevationPermission = useSelector(({ basicInfo }) => basicInfo?.permissions?.elevation);

    useEffect(() => {
        if (sidebarVisible) {
            dispatch(showSidebar());
        } else {
            dispatch(hideSidebar());
        }
    }, [location.pathname, sidebarVisible, dispatch]);

    useEffect(() => {
        const handleClickOutside = e => {
            if (listRef.current && !listRef.current.contains(e.target)) {
                dispatch({ type: TOGGLE_SIDEBAR });
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [listRef, dispatch]);

    if (!isLoggedIn) return null;

    return (
        <ReactAdminSidebar
            className={cn({
                [classes.sidebar]: true,
                [classes.sidebarMobile]: onMobile,
            })} classes={null}>
            <List className={cn({ [classes.mobileList]: onMobile })} ref={listRef}>
                {getSidebarItems(permissions).map(item => (
                    <SidebarListItem
                        key={item.label}
                        href={item.href}
                        label={item.label}
                        gka={item.gka}
                        external={item.external}
                        onClick={item.onClick || null }
                        disabled={item.disabled}
                        needsDetails={item.gka === 'profile' && profileNeedsDetails}
                        active={
                            item.gka ===
                            getActiveSidebarItemGka(location.pathname, professionalId, firmId)
                        }
                    />
                ))}
                <>
                    <SidebarHeading text={'Elevation'}/>
                    <SidebarListItem
                        key={'elevation'}
                        href={'/elevation-dashboard'}
                        label={'Dashboard'}
                        gka={'elevation'}
                        active={
                            'elevation-dashboard' ===
                            getActiveSidebarItemGka(
                                location.pathname,
                                professionalId,
                                firmId,
                            )
                        }
                        disabled={!elevationPermission}
                    />
                    <SidebarListItem
                        key={'elevation-client-answers'}
                        href={'/elevation-client-answers'}
                        label={'Client Answers'}
                        gka={'elevation-client-answers'}
                        active={
                            'elevation-client-answers' ===
                            getActiveSidebarItemGka(
                                location.pathname,
                                professionalId,
                                firmId,
                            )
                        }
                        disabled={!elevationPermission}
                    />
                    <SidebarListItem
                        key={'action-list'}
                        href={'/action-list'}
                        label={'Action List'}
                        gka={'action-list'}
                        active={
                            'action-list' ===
                            getActiveSidebarItemGka(
                                location.pathname,
                                professionalId,
                                firmId,
                            )
                        }
                        disabled={!elevationPermission}
                    />
                </>
                <SidebarHeading text={'Verified'}/>
                {getVerifiedSideBars(professionalId, permissions).map(item => (
                    <SidebarListItem
                        key={item.label}
                        href={item.href}
                        label={item.label}
                        gka={item.gka}
                        external={item.external}
                        onClick={item.onClick || null}
                        disabled={item.disabled}
                        needsDetails={item.gka === 'profile' && profileNeedsDetails}
                        active={
                            item.gka ===
                            getActiveSidebarItemGka(location.pathname, professionalId, firmId)
                        }
                        professionalId = {professionalId}
                    />
                ))}

                <SidebarHeading text={'Settings'}/>
                {getSettingsSideBars(professionalId, permissions).map(item => (
                    <SidebarListItem
                        key={item.label}
                        href={item.href}
                        label={item.label}
                        gka={item.gka}
                        external={item.external}
                        onClick={item.onClick || null}
                        disabled={item.disabled}
                        needsDetails={item.gka === 'profile' && profileNeedsDetails}
                        active={
                            item.gka ===
                            getActiveSidebarItemGka(location.pathname, professionalId, firmId)
                        }
                    />
                ))}

                {onMobile && (
                    <>
                        <SidebarListItem
                            label="Logout"
                            gka="logout"
                            onClick={() => logout('/login')}
                        />
                    </>
                )}
            </List>
        </ReactAdminSidebar>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: basicInfo.id,
        permissions: basicInfo.permissions,
        isLoggedIn: basicInfo.is_logged_in,
        firmId: basicInfo.firm_id,
        basicProfileComplete: _.get(basicInfo, 'permissions.basic_profile_complete', true),
        profileNeedsDetails: !basicInfo.gender
    };
};

const actions = {
    showSidebar,
    hideSidebar,
};

export default connect(
    mapStateToProps,
    actions
)(Sidebar);
