import { get, isEmpty } from 'lodash';
import { parse } from 'query-string';
import { GET_ONE } from 'react-admin';
import { LOCAL_STORAGE, LOGGED_OUT_AND_SIDEBAR_URLS, API_KEYS, HTTP } from './constants';
import { checkCookiesAndInitMixpanel, createBasicInfo, isImpersonator } from './utils';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module';
import tokenManager from './tokenManager';

const {
    LEXIKTOKEN,
    BASIC_INFO,
    REDIRECT_URL,
    AUTH_ERROR_MESSAGE,
} = LOCAL_STORAGE;

const showAuthLoginErrorMessage = error => {
    const type = get(error, 'type');
    const email = get(error, 'email');

    sessionStorage.setItem(AUTH_ERROR_MESSAGE, JSON.stringify({ type, email }));
};

const authProvider = {
    history: null,
    dataProvider: null,

    verifyCode: function (code) {
        const url = API_KEYS.api_url+'/auth/mfa-email'
        const request = new Request(url, {
            body: JSON.stringify({
                data: {
                    authCode: code,
                },
            }),
            method: 'POST',
            headers: new Headers({
                'Vf-Token': localStorage.getItem(LEXIKTOKEN),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }),
        });

        return fetch(request)
            .then((response) => {
                if (!response.ok && response.status !== HTTP.UNAUTHORIZED) {
                    throw new Error(response.statusText)
                }

                return response.json()
            })
            .then((response) => {
                if (!response.login) {
                    return this.logout()
                }

                if (response.message && !(response['2fa_complete'] ?? false)) {
                    return {
                        success: false,
                        message: response.message,
                    }
                }

                if (response.token) {
                    tokenManager.setToken(response.token)

                    return { success:true }
                }

                throw new Error(`Endpoint ${url} failed in an unexpected way`);
            })
    },

    resendCode: function () {
        const url = API_KEYS.api_url+'/auth/resend-email'
        const request = new Request(url, {
            method: 'POST',
            headers: new Headers({
                'Vf-Token': localStorage.getItem(LEXIKTOKEN),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }),
        })

        return fetch(request)
            .then((response) => {
                if (!response.ok && response.status !== HTTP.UNAUTHORIZED) {
                    throw new Error(response.statusText);
                }

                return response.json()
            })
            .then((response) => {
                if (!response.login) {
                    // You're not logged in anymore, get out.
                    return this.logout();
                }

                if (response.message) {
                    return {
                        error: !response.login,
                        message: response.message
                    }
                }

                throw new Error(`Endpoint ${url} failed in an unexpected way`);
            })
    },

    // called when the user attempts to log in
    login: function ({ username, password }){
        const request = new Request(API_KEYS.login_url, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: { 'Content-Type': 'application/json' },
        })

        return fetch(request)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }

                return response.json()
            })
            .then(async ({ token, error }) => {
                if (error) {
                    return showAuthLoginErrorMessage(error)
                }

                tokenManager.setToken(token)
                if (this.dataProvider) {
                    const basicInfo = await this.dataProvider(GET_ONE, 'professional/basic-info', {})
                    const formattedBasicInfo = createBasicInfo(basicInfo)

                    localStorage.setItem(BASIC_INFO, JSON.stringify(formattedBasicInfo))

                    checkCookiesAndInitMixpanel()

                    return formattedBasicInfo
                }
            })
    },

    logout: function () {
        localStorage.clear()

        try {
            //disable tracking first
            mixpanel.disable()
            //reset user
            mixpanel.reset()
        } catch (e) {
            // Errors when Mixpanel has not been initialised.
            // Since ._flags is not defined on the non-initialized object it errors when trying to .disable()
        }

        if (this.history) {
            return Promise.resolve(this.history.push('/login'))
        }

        window.location.href = '/login'

        return Promise.resolve()
    },

    checkAuth: function () {
        const token = tokenManager.getLoggedInToken();

        if (token && tokenManager.isTwoFactor()) {
            if (this.history) {
                this.history.push('/2fa')

                return Promise.resolve()
            }
        }

        return token ?  Promise.resolve() : Promise.reject()
    },

    checkError: function (error) {
        if (error.status === HTTP.UNAUTHORIZED || error.status === HTTP.FORBIDDEN) {
            return Promise.reject("Accounts request to server: authentication failed")
        }

        return Promise.resolve()
    },

    getPermissions: function () {
        const lsLexiktoken = localStorage.getItem(LEXIKTOKEN)

        if (!lsLexiktoken) {
            var q = parse(window.location.search)

            let lexiktoken = q.lexiktoken

            if (lexiktoken) {
                localStorage.setItem(LEXIKTOKEN, lexiktoken)

                if (this.dataProvider) {
                    this.dataProvider(GET_ONE, 'professional/basic-info', {}).then(response => {
                        localStorage.setItem(BASIC_INFO, JSON.stringify(createBasicInfo(response)))
                    });

                    const basicInfo = localStorage.getItem(BASIC_INFO)
                    const localBasicInfo = basicInfo ? JSON.parse(basicInfo).data : null
                    const role = localBasicInfo ? localBasicInfo.permissions : {}

                    return Promise.resolve(role)
                }
            }
        }

        const basicInfo = localStorage.getItem(BASIC_INFO)
        const localBasicInfo = basicInfo ? JSON.parse(basicInfo).data : null
        const role = localBasicInfo ? localBasicInfo.permissions : {}
        const { pathname, search } = window.location
        const matchFound = new RegExp('(' + LOGGED_OUT_AND_SIDEBAR_URLS.join('|') + ')')

        if (isEmpty(role) && !matchFound.test(pathname)) {
            sessionStorage.setItem(REDIRECT_URL, pathname + search)
            if (!isImpersonator()) {
                this.history.push('/login')

                return Promise.reject('Rejecting because of empty token/permissions')
            }
            return Promise.resolve()
        }

        return Promise.resolve(role)
    },

    getIdentity: function () { throw new Error('getIdentity is not implemented') },
};

export default authProvider;