import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import Actions from './Actions';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    header: PropTypes.any,
    image: PropTypes.any,
    disabled: PropTypes.bool,
    children: PropTypes.any,
    actions: PropTypes.any,
    upgradeProps: PropTypes.object,
};

const defaultProps = {
    grid: {
        xs: 12,
        lg: 6,
        xl: 3,
    },
    upgradeProps: {
        message: 'Please upgrade your plan to access this feature',
        href: undefined,
    },
};

const styles = theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
        boxSizing: 'border-box',
        border: 'solid 1px lightgray',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    cardContent: {
        padding: 0,
    },
    overlay: {
        opacity: '0.31',
        pointerEvents: 'none',
    },
    imageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px -24px -34px -24px',
        minHeight: theme.spacing(20),
        [theme.breakpoints.down('sm')]: {
            position: 'inherit',
        },
        '& img': {
            maxWidth: 370,
            width: '100%'
        }
    },
    actions: {
        margin: 'auto',
        marginTop: 0,
    },
});

const EmailSignatureCard = props => {
    const {
        classes,
        header,
        image,
        grid,
        cardDisabled,
        disabled,
        actions,
        upgradeProps,
        upgradeUrl,
        firmRepTool,
    } = props;
    const { href, message, ...upgradePropsRest } = upgradeProps;
    const isDisabled = firmRepTool ? false : cardDisabled || !!disabled;

    return (
        <Grid item {...grid}>
            <Card className={classes.container}>
                <div className={isDisabled ? classes.overlay : null}>
                    <CardContent className={classes.cardContent}>
                        <div className={classes.imageWrapper}>
                            {header ? header : <img src={image} alt="Email Signature" />}
                        </div>
                    </CardContent>
                </div>
                <div className={classes.actions}>
                    <Actions
                        disabled={isDisabled}
                        href={href ? href : upgradeUrl}
                        message={message}
                        actions={actions}
                        buttonProps={{ ...upgradePropsRest }}
                    />
                </div>
            </Card>
        </Grid>
    );
};

EmailSignatureCard.propTypes = propTypes;
EmailSignatureCard.defaultProps = defaultProps;

const mapStateToProps = ({ basicInfo }) => {
    return {
        upgradeUrl: `/account-settings/${basicInfo.id}`,
        cardDisabled: _.get(basicInfo, ['permissions', 'reputation_tools']) === false,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(EmailSignatureCard));
