import React from 'react';
import { get } from 'lodash';
import { Admin, Resource } from 'react-admin';
import { createBrowserHistory } from 'history';
import messages from './translations/messages';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import dataProvider from './dataProvider';
import Review from './Review';
import FirstImpressions from './Review/FirstImpressions';
import Enquiries from './Enquiries';
import MainDashboard from './Dashboard';
import MyTheme from './MyTheme';
import MyLayout from './MyLayout';

import authProvider from './authProvider';
import SidebarMenu from './AppLayout/Sidebar/Sidebar';
import customRoutes from './customRoutes';
import reducers from './state/reducers';
import mySaga from './state/sagas';
import Qualifications from './Qualifications';
import Jobs from './Jobs';
import Awards from './Awards';
import MeetingLocations from './MeetingLocations';
import Fees from './Fees';
import Services from './Services';
import Languages from './Languages';
import SettingsBilling from './SettingsBilling';
import BasicProfile from './Professional';
import BasicProfileEdit from './BasicProfile/BasicProfileEdit';
import FirstInvitationCreate from './Review/FirstInvitation/FirstInvitationCreate';
import Login from './Login/Login';
import Firm from './Firm';
import ReferralGenerator from './ReferralGenerator/ReferralGenerator';
import CustomerList from './ReferralGenerator/CustomerList';
import CustomerReferralList from './ReferralGenerator/CustomerReferralList';
import { VERTICAL, VERIFY_SERVICE_ROUTES } from './constants';
import { createGenerateClassName, StylesProvider } from '@material-ui/core/styles';
import * as analytics from './analytics';
import ReviewSetting from './Review/ReviewSetting';
import AppHelmet from "./AppHelmet";
import * as Sentry from "@sentry/react";
import { INTEGRATION_KEYS } from './constants/api-keys'
import ActionList from './ActionList';
import { NotFound } from './NotFound';
import ReputationTools from './ReputationTools';

export const historyList = sessionStorage.getItem('historyList')
    ? JSON.parse(sessionStorage.getItem('historyList'))
    : [];

export const history = createBrowserHistory();

const i18nProvider = polyglotI18nProvider(() => messages, 'en');

history.listen(item => {
    historyList.push(item.pathname);
    sessionStorage.setItem('historyList', JSON.stringify(historyList));
});

analytics.activate();

Sentry.init({
  dsn: INTEGRATION_KEYS.sentry_dsn,
  environment: INTEGRATION_KEYS.sentry_env,
  tracesSampleRate: INTEGRATION_KEYS.sentry_env.includes('prod') ? 0.05 : 1.0,
  attachStacktrace: true,
  replaysSessionSampleRate: INTEGRATION_KEYS.sentry_env.includes('prod') ? 0 : 0.1,  
  replaysOnErrorSampleRate: INTEGRATION_KEYS.sentry_env.includes('prod') ? 0 : 0.1,
  integrations: [new Sentry.Replay()],
});

const FeeResources = [
    <Resource name="fees" create={Fees.create} list={Fees.list} />,
    <Resource name="fees-edit" list={Fees.edit} />,
    <Resource name="mortgage-fee-levels" create={Fees.mortgageLevels.create} edit={Fees.mortgageLevels.edit} />,
    <Resource name="investment-fee-levels" create={Fees.investmentLevels.create} edit={Fees.investmentLevels.edit} />,
    <Resource name="fees/choices" />,
    <Resource name="fees-insights" list={Fees.insights} />,
];

const VerifiedOnlyResources= [
    <Resource name="reputation" list={ReputationTools.list} />,
    <Resource name="fees-insights" insights={Fees.insights} />,
    <Resource name="fees" list={Fees.list} />,
]

const FirmAdminResources = [
    <Resource name="firm/firm-fca" />,
    <Resource name="firm/linked-advisers" list={Firm.linkedAdvisers} />,
    <Resource name="firm" edit={Firm.editDetails} />,
    <Resource name="firm-reviews" list={Firm.reviews} edit={Firm.reviewEdit} />,
]

const ReferralGeneratorResources = [
    <Resource name="referral-generator" create={ReferralGenerator} />,
    <Resource name="customers/referral-customers" list={CustomerList} />,
    <Resource name="customer-referral" list={CustomerReferralList} />,
];


const App = () => {
    const authProviderWithHooks = () => {
        authProvider.history = history
        authProvider.dataProvider = dataProvider;

        return authProvider
    }

    return (
    <>
        <AppHelmet />
        <StylesProvider generateClassName={createGenerateClassName()}>
            <Admin
                title={null}
                menu={SidebarMenu}
                loginPage={Login}
                appLayout={MyLayout}
                history={history}
                theme={MyTheme}
                customRoutes={customRoutes}
                customReducers={reducers}
                customSagas={[mySaga]}
                dashboard={MainDashboard}
                authProvider={authProviderWithHooks()}
                dataProvider={dataProvider}
                i18nProvider={i18nProvider}
                catchAll={NotFound}
            >
                {permissions =>
                    get(permissions, 'basic_profile_complete')
                        ? [
                              <Resource name="reviews" list={Review.list} edit={Review.edit} />,
                              <Resource name="customer-master" edit={Review.createAutoInvite} />,
                              <Resource name="review-setting/template" />,
                              <Resource name="review-setting" list={ReviewSetting.edit} />,
                              <Resource name="first-impressions" list={FirstImpressions.list} edit={FirstImpressions.edit} />,
                              <Resource name="enquiries" edit={Enquiries.edit} />,
                              <Resource name="enquiries-list" edit={Enquiries.list} />,
                              <Resource name="services-list" edit={BasicProfile.services} />,
                              <Resource name="professional/about" edit={BasicProfile.about} />,
                              <Resource name="professional/disclaimer" edit={BasicProfile.disclaimer} />,
                              <Resource name="professional/qualifications" edit={BasicProfile.qualifications} />,
                              <Resource name="professional/experience" edit={BasicProfile.experience} />,
                              <Resource name="professional/location" edit={BasicProfile.location} />,
                              <Resource name="professional/firm" edit={Firm.details} />,
                              <Resource name="qualifications" create={Qualifications.create} edit={Qualifications.edit}  />,
                              <Resource name="certificates" />,
                              <Resource name="awards" create={Awards.create} edit={Awards.edit} />,
                              <Resource name="jobs" create={Jobs.create} edit={Jobs.edit} />,
                              <Resource name="meeting-locations" create={MeetingLocations.create} edit={MeetingLocations.edit} />,
                              <Resource name="services" create={Services.create} edit={Services.edit} />,
                              <Resource name="languages" create={Languages.create} />,
                              <Resource name="languages-choices" />,
                              <Resource name="services-choices" />,
                              <Resource name="enquiry/report" edit={Enquiries.report} />,
                              <Resource name="account-settings" edit={SettingsBilling.accountSettings} />,
                              <Resource name="billing" list={SettingsBilling.billing.list} show={SettingsBilling.billing.show} />,
                              <Resource name="review/previous-invites"  list={Review.listPreviousReviews} />,
                              <Resource name="review/first-invitation" create={FirstInvitationCreate} />,
                              <Resource name={VERIFY_SERVICE_ROUTES.VERIFY} edit={Services.verify} />,
                              <Resource name={VERIFY_SERVICE_ROUTES.SERVICES} create={Services.create} edit={Services.edit} />,
                              <Resource name={VERIFY_SERVICE_ROUTES.CHOICES} />,
                              <Resource name="order" />,
                              <Resource name="products" />,
                              <Resource name="dashboard/table-data" />,
                              [get(permissions, 'elevation') === true ? <Resource name="elevation/goals" /> : []],
                              [get(permissions, 'elevation') === true ? <Resource name="elevation/drivers" /> : []],
                              [get(permissions, 'elevation') === true ? <Resource name="elevation/answers" /> : []],
                              [get(permissions, 'elevation') === true ? <Resource name="elevation/answers/answer-choices" /> : []],
                              [get(permissions, 'elevation') === true ? <Resource name="elevation-statistics" /> : []],
                              [get(permissions, 'elevation') === true ? <Resource name="action" /> : []],
                              [get(permissions, 'elevation') === true ? <Resource name="action-list" list={ActionList.list} /> : []],
                              [get(permissions, 'elevation') === true ? <Resource name="action-list/name-choices" /> : []],
                              [get(permissions, 'is_firm_admin') === true ? FirmAdminResources : []],
                              [get(permissions, 'vertical') === VERTICAL.FINANCIAL_ADVISER ? FeeResources : []],
                              [get(permissions, 'can_use_referral_generator') === true ? ReferralGeneratorResources : []],
                              [get(permissions, 'can_access_verified_sidebars') === true ? VerifiedOnlyResources : []],
                        ]
                        : [
                              <Resource name="basic-profile" edit={BasicProfileEdit} />,
                              <Resource name="dashboard/table-data" />,
                              <Resource name="services" create={Services.create} edit={Services.edit} />,
                              <Resource name="services-choices" />,
                              FeeResources,
                          ]
                }
            </Admin>
        </StylesProvider>
    </>
)};

export default App;
