import React from 'react';
import PageTabs from '../../AppLayout/PageTabs';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(2),
    },
});

const ManageFeedbackTabs = ({ classes }) => {
    const routes = [
        {
            label: 'My Reviews',
            link: '/reviews',
        },
        {
            label: 'First Impressions',
            link: '/first-impressions',
        },
        {
            label: 'Annual Feedback',
            link: '/review-setting',
        },
    ];
    
    return (
        <div className={classes.root}>
            <PageTabs routes={routes} />
        </div>
    );
};

export default withStyles(styles)(ManageFeedbackTabs);
