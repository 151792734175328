import React, { useEffect } from 'react';
import { includes } from 'lodash';
import { usePermissions } from 'react-admin';
import { showSidebar } from '../../state/actions';
import { track } from '../../analytics';
import PrivateDashboardStatsV2 from './PrivateDashboardStatsV2';
import PublicProfileUpgradeV2 from './PublicProfileUpgradeV2';
import WelcomePanel from '../DashboardV3/Components/WelcomePanel';
import { connect } from 'react-redux';
import { get as lodashGet } from 'lodash';
const PrivateDashboardV2 = props => {
    const { showSidebar, welcomePanelData } = props;
    const { permissions } = usePermissions();

    const reviewAndFiCountData = lodashGet(welcomePanelData, 'review_and_fi_count', 0)

    useEffect(() => {
        showSidebar();

        track('Viewed dashboard', { category: 'Dashboard card' });
    }, [showSidebar]);

    return (
        <div>
            <WelcomePanel data={welcomePanelData}/>
            { permissions?.elevation && <PrivateDashboardStatsV2 reviewAndFiCountData={reviewAndFiCountData}/>}
            {includes(permissions?.firm_features, 'firm_opted_out_of_accounts_upsell') ? null : <PublicProfileUpgradeV2 /> }
        </div>
    );
};

const actions = {
    showSidebar,
};

export default connect(
    null,
    actions
)(PrivateDashboardV2);
