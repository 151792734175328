import { ASSET_DOMAIN, AWS_PROFESSIONALS_DOMAIN } from './external-urls';

export const LOGO_WHITE = `${ASSET_DOMAIN}/img/vf2023/logo-white.png`;
export const LOGO = `${ASSET_DOMAIN}/img/vf2023/logo.png`;

export const GUIDE_BANNER_LARGE = `${ASSET_DOMAIN}/img/guides/guide_image_v2.png`;
export const ACCOUNTANTS_GUIDE_BANNER_LARGE_BG = `${ASSET_DOMAIN}/img/guides/accountants_guide_large_bg.jpg`;
export const FA_GUIDE_2020_BANNER_LARGE_BG = `${ASSET_DOMAIN}/img/guides/fa_guide_2020_large_bg.png`;
export const ACCOUNTANTS_GUIDE_LOGOS = `${ASSET_DOMAIN}/img/guides/accountants_guide_mobile_logos_2020.png`;
export const ACCOUNTANTS_GUIDE_BANNER_MOBILE = `${ASSET_DOMAIN}/img/guides/accountants_guide_mobile_bg.jpg`;
export const ACCOUNTANTS_GUIDE_LOGOS_LARGE = `${ASSET_DOMAIN}/img/guides/accountants_guide_logo_large_2020.png`;
export const FA_GUIDE_LOGO_2020 = `${ASSET_DOMAIN}/img/guides/fa_guide_logo2020.png`;
export const FA_GUIDE_BANNER_MOBILE_2020 = `${ASSET_DOMAIN}/img/guides/fa_guide_banner_2020_mobile.jpg`;
export const FA_GUIDE_BANNER_LARGE_2020 = `${ASSET_DOMAIN}/img/guides/fa_guide_banner_2020_large.jpg`;
export const GUIDE_BANNER_BG = `${ASSET_DOMAIN}/img/accounts/mini_banner_bg.jpg`;
export const GUIDE_BANNER_LEFT = `${ASSET_DOMAIN}/img/accounts/mini_banner_logos-left.png`;
export const GUIDE_BANNER_RIGHT = `${ASSET_DOMAIN}/img/accounts/mini_banner_logos-right.png`;
export const GUIDE_LOGO = `${ASSET_DOMAIN}/img/accounts/mini_banner_guide.png`;
export const GUIDE_CLIENTS = `${ASSET_DOMAIN}/img/accounts/mini_banner_logos.png`;
export const GUIDE_CLIENTS_V2 = `${ASSET_DOMAIN}/img/accounts/mini_banner_logos-left-v2.png`;
export const THE_TIMES_LOGO = `${ASSET_DOMAIN}/img/guides/the_times_logo.png`;
export const REPUTATION_STARS = `${ASSET_DOMAIN}/img/accounts/stars.png`;
export const REPUTATION_SHARE = `${ASSET_DOMAIN}/img/accounts/sharesymbol.png`;
export const REPUTATION_PEOPLE = `${ASSET_DOMAIN}/img/home/peopleicon.png`;

export const CERTIFICATE_OF_EXCELLENCE = `${ASSET_DOMAIN}/img/accounts/certificate_excellence.jpg`;
export const WEBSITE_WIDGET = `${ASSET_DOMAIN}/img/accounts/website_widget.jpg`;
export const EMAIL_SIGNATURE = `${ASSET_DOMAIN}/img/accounts/email_signature.jpg`;
export const REVIEW_PORTFOLIO = `${ASSET_DOMAIN}/img/accounts/review_portfolio.jpg`;
export const FEE_BADGE = `${ASSET_DOMAIN}/img/fee-badge.jpg`;
export const FEE_BADGE_WHITE = `${ASSET_DOMAIN}/img/fee-badge-white.png`;
export const FEES_SIGN_UP_ROUND_1 = `${ASSET_DOMAIN}/img/fees-signup-round-1.png`;
export const FEES_SIGN_UP_ROUND_2 = `${ASSET_DOMAIN}/img/fees-signup-round-2.png`;
export const FEES_SIGN_UP_ROUND_3 = `${ASSET_DOMAIN}/img/fees-signup-round-3.png`;

export const REP_TOOL_FIRM_REVIEWS_DARK = `${ASSET_DOMAIN}/img/accounts/reputation-tools/firm-reviews-widget-dark.png`;
export const REP_TOOL_FIRM_REVIEWS_LIGHT = `${ASSET_DOMAIN}/img/accounts/reputation-tools/firm-reviews-widget-light.png`;
export const REP_TOOL_FIRM_BADGE = `${ASSET_DOMAIN}/img/accounts/reputation-tools/firm-top-rated-badge-thumbnail.png`;
export const REP_TOOL_FIRM_BADGE_LIGHT = `${ASSET_DOMAIN}/img/accounts/reputation-tools/firm-top-rated-badge-2022-light.png`;
export const REP_TOOL_FIRM_BADGE_DARK = `${ASSET_DOMAIN}/img/accounts/reputation-tools/firm-top-rated-badge-2022-dark.png`;
export const REP_TOOL_FIRM_BADGE_STARS = `${ASSET_DOMAIN}/img/accounts/reputation-tools/firm-top-rated-badge-2022-stars.png`;
export const REP_TOOL_CERTIFICATE_OF_EXCELLENCE = `${ASSET_DOMAIN}/img/accounts/reputation-tools/certificate-of-excellence.png`;
export const REP_TOOL_TOP_RATED_CERTIFICATE = `${ASSET_DOMAIN}/img/accounts/reputation-tools/guide-top-rated-certificate.png`;
export const REP_TOOL_EMAIL_SIGNATURE = `${ASSET_DOMAIN}/img/accounts/reputation-tools/email-signature.png`;
export const REP_TOOL_REVIEW_STARS = `${ASSET_DOMAIN}/img/accounts/reputation-tools/review-stars.png`;
export const REP_TOOL_REVIEW_PDF = `${ASSET_DOMAIN}/img/accounts/reputation-tools/reviews-pdf.png`;

export const ELEVATION = `${ASSET_DOMAIN}/img/accounts/Elevation_mock_up_update.png`;
export const ADVISER_PROFILE  = `${ASSET_DOMAIN}/img/accounts/Adviser_profile_mock_up_update.png`;
export const CONSUMER_DUTY_REPORT = `${ASSET_DOMAIN}/img/accounts/Consumer_Duty_report_mock_up_update.png`;
export const TOP_RATED_GUIDE = `${ASSET_DOMAIN}/img/accounts/Top_Rated_Guide_2023_update_V2.png`;

export const PROFESSIONAL_PHOTO_MADELEINE_SUNLEY = `${AWS_PROFESSIONALS_DOMAIN}/5f19b083aaad6.png`;
export const PROFESSIONAL_PHOTO_WAYNE_GRIFFITHS = `${AWS_PROFESSIONALS_DOMAIN}/dd7a14016d2872ac3e8a2af3e54b3dbfe7cf97b4.png`;

export const HERO_IMAGE_WITH_ELEVATION_REGISTER = `${ASSET_DOMAIN}/img/accounts/Adviser_Header_New_Image_100.png`;

export const REP_TOOL_FIRM_REVIEWS_DARK_VF_2023 = `${ASSET_DOMAIN}/img/accounts/reputation-tools/vf2023/firm-reviews-widget-dark-vf-2023.png`;
export const REP_TOOL_FIRM_REVIEWS_LIGHT_VF_2023 = `${ASSET_DOMAIN}/img/accounts/reputation-tools/vf2023/firm-reviews-widget-light-vf-2023.png`;

export const REP_TOOL_FIRM_BADGE_TOP_RATED_2023 = `${ASSET_DOMAIN}/img/accounts/reputation-tools/vf2023/VF_Final_Colour_Top_Rated_Advice_Firm_Ribbon_Horizontal_2023.png`;
export const REP_TOOL_FIRM_BADGE_TOP_RATED_2024 = `${ASSET_DOMAIN}/img/accounts/reputation-tools/vf2024/VF_Final_Colour_Top_Rated_Advice_Firm_Ribbon_Horizontal_2024.png`;
export const REP_TOOL_FIRM_BADGE_PLACEHOLDER_VF_2023 = `${ASSET_DOMAIN}/img/accounts/reputation-tools/firm-top-rated-badge-placeholder-vf-2023.png`;

export const REP_TOOL_CERTIFICATE_OF_EXCELLENCE_VF_2023 = `${ASSET_DOMAIN}/img/accounts/reputation-tools/vf2023/certificate-of-excellence-vf-2023.png`;
export const REP_TOOL_TOP_RATED_CERTIFICATE_VF_2023 = `${ASSET_DOMAIN}/img/accounts/reputation-tools/vf2023/guide-top-rated-certificate-vf-2023.png`;
export const REP_TOOL_REVIEW_PDF_VF_2023 = `${ASSET_DOMAIN}/img/accounts/reputation-tools/vf2023/reviews-pdf-vf-2023.png`;

export const REP_TOOL_REVIEW_STARS_VF_2023 = `${ASSET_DOMAIN}/img/accounts/reputation-tools/vf2023/review-stars-placeholder-vf-2023.png`;
export const TOP_RATED_ADVISER_VF = `${ASSET_DOMAIN}/img/accounts/VF_Top_Rated_Adviser_2025_Flat_Top_Certificate.png`;
