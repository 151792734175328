import React from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { setSidebarVisibility, usePermissions } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { closeSharePopover } from '../../utils';
import * as analytics from '../../analytics';
import { Tooltip, Typography } from '@material-ui/core';
import { Zoom } from '@material-ui/core';
import { DISABLED_MENU_ELEVATION_TOOLTIP, ELEVATION_SIDEBAR_GKAS } from '../../constants';
import { vfColours } from '../../MyTheme';

const useStyles = makeStyles(theme => ({
    active: {
        color: theme.palette.secondary.main,
        '& .MuiTypography-root': {
            color: theme.palette.secondary.main
        }
    },
    label: {
        '& > span': {
            margin: 0,
        },
    },
    icon: {
        color: 'inherit',
        minWidth: theme.spacing(5),
    },
    tooltipText: {
        padding: '1rem',
        fontSize: '0.8rem',
        color: theme.palette.error.main,
    },
    disabled: {
        color: theme.palette.tertiary.dark,
    },
}));

export const NeedsDetail = props => {
    return (
        <Tooltip
            title={'Needs details'}
            placement="top"
            TransitionComponent={Zoom}
            arrow>
            <span className={'needs-details'} style={{
                backgroundColor: vfColours.grassGreen,
                color: vfColours.navyBlue,
                padding: '1px 8px',
                borderRadius: '100%',
                fontSize: 12,
                fontWeight: 700,
                position: 'relative',
                marginRight: '4px',
                marginLeft: '4px',
                top: -2
            }}>!</span>
        </Tooltip>

)
}

const SidebarListItem = props => {
    const {
        href,
        label,
        gka,
        active,
        history,
        onClick,
        external,
        disabled,
        needsDetails = false,
        professionalId = null,
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const onMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const { permissions } = usePermissions();
    const isElevationSidebar = ELEVATION_SIDEBAR_GKAS.includes(gka);

    const handleClick = () => {
        if (disabled) {
            history.push('/');
            return;
        }

        if (onClick) {
            onClick();

            return;
        }

        if (active) {
            return;
        }

        closeSharePopover();

        if (onMobile) {
            dispatch(setSidebarVisibility(false));
        }

        if (external) {
            window.open(href);
            analytics.track(`clicked 'help centre' in sidebar`, { category: 'Support' });
            if (gka === 'help') {
                analytics.track(`clicked 'help centre' in sidebar`, { category: 'Support' });
            }
        } else {
            history.push(href);
        }
    };

    const getItemLabel = () => {
        if (needsDetails && !permissions?.elevation_private_mode) {
            return <span><span style={{marginRight: 2}}>{label}</span> <NeedsDetail /></span>;
        }
        return label
    }

    const renderListItem = () => {
        return (
            <ListItem
                button
                className={cn({
                    [classes.active]: active,
                    [classes.disabled]: disabled,
                })}
                onClick={handleClick}>
                <ListItemText className={classes.label} primary={getItemLabel()} />
            </ListItem>
        );
    };

    const DisabledTooltipMessage = () => {
        if (isElevationSidebar) {
            return (
                <Typography className={classes.tooltipText}>{DISABLED_MENU_ELEVATION_TOOLTIP}</Typography>
            )
        }

        return (
            <Typography className={classes.tooltipText}>
                To access this feature you need to <a href={`/account-settings/${professionalId}`}>upgrade to Verified.</a>
            </Typography>
        )
    }

    return (
        <>
            {disabled ? (
                <Tooltip
                    title={<DisabledTooltipMessage />}
                    placement="top"
                    TransitionComponent={Zoom}
                    interactive={!isElevationSidebar}
                    arrow
                >
                    <div>{renderListItem()}</div>
                </Tooltip>
            ) : (
                renderListItem()
            )}
        </>
    );
};

export default withRouter(SidebarListItem);
