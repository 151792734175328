import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { get as lodashGet } from 'lodash';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useHomePageStyles = makeStyles(theme => ({
    loggedInAs: {
        color: `${theme.palette.error.main} !important`,
    },
    list: {
        '& li::marker': {
            margin: 'auto',
        },
        '& li': {
            paddingTop: 0,
            paddingBottom: 0,
            '&:not(:first-child)': {
                '&:before': {
                    content: '""',
                    display: 'inline-block',
                    borderRadius: '50%',
                    position: 'absolute',
                },
                color: 'white',
            },
            '&:not(:last-child)': {
                paddingRight: 10,
            },
            '&:first-child': {
                listStyleType: 'none',
                paddingTop: '0.25rem',
            },
        },
    },
    listItem: {
        fontSize: theme.typography.fontSize,
        position: 'relative',

        '& > p': {
            margin: 0,
        },
    },
}));

const useStyles = makeStyles(theme => ({
    loggedInAs: {
        color: `${theme.palette.error.main} !important`,
    },

    list: {
        '& li': {
            '&:not(:first-child)': {
                paddingLeft: theme.spacing(2),
                '&:before': {
                    content: '""',
                    display: 'inline-block',
                    width: theme.spacing(1) / 2,
                    height: theme.spacing(1) / 2,
                    borderRadius: '50%',
                    background: theme.palette.primary.main,
                    position: 'absolute',
                    top: 16,
                    left: 0,
                    margin: `0 6px`,
                },
            },
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 5,
            marginTop: 10,
        },
    },
    listItem: {
        fontSize: theme.typography.fontSize,
        position: 'relative',

        '& > p': {
            margin: 0,
        },
    },
}));

const HeaderProfessionalDetails = (
    {
        isImpersonating,
        firstName,
        contactable,
        plan,
        verifiedSince,
        isHomePage = false,
        data = [],
        canAccessVerifiedSidebars,
    }) => {
    const homePageStyles = useHomePageStyles();
    const defaultStyles = useStyles();
    const classes = isHomePage ? homePageStyles : defaultStyles;

    const ProfessionalDetails = () => (
        <>
            <li className={classes.listItem}>
                {contactable ? (
                    <Typography variant="caption">Available for contact</Typography>
                ) : (
                    <Typography color="error" variant="caption">
                        Unavailable For Contact
                    </Typography>
                )}
            </li>
            {!isHomePage && plan && (
                <li className={classes.listItem}>
                    <Typography variant="caption">{`${plan} Plan`}</Typography>
                </li>
            )}
            <li className={classes.listItem}>
                {verifiedSince ? (
                    <Typography variant="caption">
                        {`Verified Since ${moment(verifiedSince).format('DD-MM-YYYY')}`}
                    </Typography>
                ) : (
                    <Typography color="error" variant="caption">
                        Not Fully Verified
                    </Typography>
                )}
            </li>
        </>
    );

    const HomePageProfessionalDetails = () => {
        return <>
            {canAccessVerifiedSidebars &&
                <ProfessionalDetails/>
            }
            <li className={classes.listItem}>
                <Typography variant="caption">{getLastReviewDate()}</Typography>
            </li>
        </>;
    };

    const getLastReviewDate = () => (
        `Last review ${lodashGet(
            data,
            'days_since_last_review',
            0,
        )} days ago`
    );

    return (
        <ul className={classes.list}>
            {isImpersonating && (
                <li className={cn(classes.loggedInAs, classes.listItem)}>
                    <Typography variant="caption">
                        {`You are currently logged in as: `}
                        <b>{firstName}</b>
                    </Typography>
                </li>
            )}
            {
                isHomePage ?
                    <HomePageProfessionalDetails/>
                    :
                    <ProfessionalDetails/>
            }
        </ul>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        isImpersonating: lodashGet(basicInfo, 'isImpersonating'),
        contactable: lodashGet(basicInfo, 'contactable'),
        firstName: lodashGet(basicInfo, 'first_name'),
        plan: lodashGet(basicInfo, 'plan'),
        verifiedSince: lodashGet(basicInfo, 'verified_since'),
        canAccessVerifiedSidebars: lodashGet(basicInfo, ['permissions', 'can_access_verified_sidebars'], false),
    };
};

export default connect(mapStateToProps)(HeaderProfessionalDetails);
